
const data_gongao=[
    {id:1,type:"【お知らせ】",title:"0731臨時維護お知らせ",content:{c_title:"亲爱的合伙人:",c_content1:"aaaaaaaaaaaaa",c_content2:"bbbbbbbbbb",c_content3:"cccccccccccc"},time:"2022-07-28"},
    {id:2,type:"【お知らせ】",title:"伺服器-夢色島嶼開服",content:{c_title:"亲爱的合伙人:",c_content1:"ddddddddddddddd",c_content2:"",c_content3:""},time:"2022-07-28"},
    {id:3,type:"【お知らせ】",title:"0729臨時維護お知らせ",content:{c_title:"亲爱的合伙人:",c_content1:"eeeeeeeeeeeeeeee",c_content2:"fffffffffffff",c_content3:""},time:"2022-07-28"},
]
const data_activity=[
    {id:1,type:"【イベント】",title:"0731臨時維護お知らせ",content:{c_title:"亲爱的合伙人:",c_content1:"ggggggggggggggg",c_content2:"",c_content3:""},time:"2022-07-28"},
    {id:2,type:"【イベント】",title:"伺服器-夢色島嶼開服",content:{c_title:"亲爱的合伙人:",c_content1:"hhhhhhhhhhhhhh",c_content2:"",c_content3:""},time:"2022-07-28"},
]
const data_gonglue=[
    {id:1,type:"【攻略】",title:"攻略1",content:{c_title:"亲爱的合伙人:",c_content1:"jjjjjjjjjjjjj",c_content2:"",c_content3:""},time:"2022-07-28"},
    {id:2,type:"【攻略】",title:"攻略2",content:{c_title:"亲爱的合伙人:",c_content1:"啊萨达撒旦记录卡设计了多款",c_content2:"",c_content3:""},time:"2022-07-28"},
    {id:3,type:"【攻略】",title:"攻略3",content:{c_title:"亲爱的合伙人:",c_content1:"啊谁说的拉萨扩大打开",c_content2:"",c_content3:""},time:"2022-07-28"},
]

export default{
    data_gongao,
    data_activity,
    data_gonglue
}