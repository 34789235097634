<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default{
  
  name:'app',
  components:{

  },
  created(){
    // document.body.removeChild(document.getElementById('load-container'))
  },
  mounted(){
    // if (this._isMobile()) {
    //   // 手机端
    //   this.$router.replace("/");
    // } else {
    //   // pc端
    //   this.$router.replace("/pc");
    // }
  },
  methods:{
    // _isMobile() {
    //   let flag = navigator.userAgent.match(
    //     /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/
    //   );
    //   return flag;
    // }
  },
}
</script>



<style>
*{
  margin: 0;
  height: auto;
  padding: 0;
  box-sizing: border-box;
}
</style>
