import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import qs from 'qs'
import Vant from 'vant';
import 'vant/lib/index.css';
import http from '../http'
import global_data from '../data'
import { Toast,Lazyload } from 'vant';
import '@/plugin/element.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VideoPlayer from 'vue-video-player'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI);
Vue.use(VideoPlayer)
Vue.use(VueAwesomeSwiper)
Vue.use(Toast)
Vue.use(Vant);
Vue.use(Lazyload);

axios.defaults.baseURL = 'api'
axios.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    if (config.method === 'post') {
      const data = qs.parse(config.data)
      config.data = qs.stringify({
        ...data
      })
    }
    if (config.method === 'get') {
      config.params = {
        ...config.params
      }
    }
    //}
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

Vue.config.productionTip = false
Vue.prototype.$http=http
Vue.prototype.$toast=Toast
Vue.prototype.$global_data=global_data
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs

router.afterEach((to,from,next) => {window,scrollTo(0,0)})

require('vant/lib/index.css')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
