import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}

VueRouter.prototype.replace = function push(location){
  return originalReplace.call(this, location).catch(err=>err)
}

// const routes = [
//   //移动端
//   {
//     path: '/',
//     name: 'home',
//     component: () => import('../views/Mobile.vue'),
//   },
//   {
//     path: '/news',
//     name: 'news',
//     component: () => import('../views/mobile/MobileNew.vue'),
//   },
//   //pc
//   {
//     path: '/pc',
//     name: 'pc',
//     component: () => import('../views/pc/pc.vue')
//   },
// ]

const routesM = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Mobile.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/mobile/MobileNew.vue'),
  },
  {
    path: '/infos',
    name: 'infos',
    component: () => import('../views/mobile/MobileInfo.vue'),
  },
]

const routesP = [
  {
    path: '/',
    name: 'pc',
    component: () => import('../views/pc/pc.vue')
  },
  {
    path: '/booking',
    name: 'booking',
    component: () => import('../views/pc/pcBook.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/pc/pcInfo.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/pc/pcNews.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/pc/admin.vue'),
    children: [
      {
        path: '/register_info',
        name: 'register_info',
        component: () => import('../views/pc/register_info.vue'),
      },
    ]
  },
]

var routes = [];
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  routes = routesM
} else {
  routes = routesP
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
